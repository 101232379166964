import "./index.css";

import Card from "../components/Card";
import Link from "gatsby-link";
import React from "react";

const IndexPage = () => (
  <div className="HomePage">
    <h1>PPI TOOLS HOME</h1>
    <div className="CardGroup">
      <Link to="/ctemplate">
        <Card title="Client" text="Click Here" />
      </Link>
      <Link to="/eng">
        <Card title="English" text="Click Here" />
      </Link>
      <Link to="/spa">
        <Card title="Spanish" text="Click Here" />
      </Link>
    </div>
  </div>
);

export default IndexPage;
